import React, { useState, useEffect } from "react";
import { Container, Button, Form } from "react-bootstrap";
import { Col, Row } from "antd";
import Logo from '../../assets/img/logo.png'

import Swal from 'sweetalert2';
import withReactContent from 'sweetalert2-react-content';
import axios from "axios";
import * as Icon from 'react-bootstrap-icons';
import { Link } from 'react-router-dom';
import { useNavigate } from 'react-router-dom';
import { Spinner } from "react-bootstrap";
import { toast } from 'react-toastify';
import { ToastContainer } from 'react-toastify';
import { IoMdWallet } from "react-icons/io";
import { MdCurrencyRupee } from "react-icons/md";

function WithdrawAmount() {
  const [withdrawAmount, setWithdrawAmount] = useState("");
  const [paymentOption, setPaymentOption] = useState("");
  const navigate = useNavigate();
  const [loadingbutton, setLoadingbutton] = useState(false);
  const [loading1, setLoading] = useState(false);
  const [users, setUsers] = useState([]);
  const [withdraw, setwithdraw] = useState([]);


  const handleWithdraw = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    // Define a map for payment option types
    const typeMap = {
      1: "phone_pay",
      2: "paytm",
      3: "google_pay",
      4: "Bank",
    };

    try {
      if (!withdrawAmount) {

        toast.error('Please Enter a Valid Digit Number.');
        return;
      }
      if (!paymentOption) {

        toast.error('Please Enter a Valid Payment Option.');
        return;
      }
      setLoadingbutton(true);
      const formData = new FormData();
      formData.append("app_id", process.env.REACT_APP_API_ID);
      formData.append("user_id", user_id);
      formData.append("amount", withdrawAmount);
      formData.append("device_id", dev_id);

      // Include additional parameters based on the payment option
      if (paymentOption === "1") {
        formData.append("type", "phonepay");
        // Add phone_pay specific parameters
      } else if (paymentOption === "2") {
        formData.append("type", "paytm");
        // Add paytm specific parameters
      } else if (paymentOption === "3") {
        formData.append("type", "googlepay");
        // Add google_pay specific parameters
      } else if (paymentOption === "4") {
        formData.append("type", "bank");
        formData.append("account_number", "account_number");
        formData.append("bank_name", "bank_name");
        formData.append("ifsc_code", "ifsc_code");
        formData.append("account_holder_name", "account_holder_name");
        // Add Bank specific parameters
      }

      const response = await fetch(`${process.env.REACT_APP_API_URL}/Withdraw.php`, {
        method: "POST",
        body: formData,
      });
      const MySwal = withReactContent(Swal);
      if (response.ok) {
        try {
          const responseData = await response.json(); // Parse the response JSON
          console.warn(responseData)

          if (responseData.success === "3") {
            MySwal.fire({
              title: responseData.message,
              icon: 'success',
              timer: 2500
            }).then((result) => {
              navigate('/');
            });
          } else {
            MySwal.fire({
              title: responseData.message,
              timer: 2000,
              icon: 'error',
            });
          }
        } catch (error) {
          console.error("Error parsing response:", error);
          MySwal.fire({
            title: "Error",
            text: "Something went wrong. Please try again.",
            icon: 'error',
          });
        }
      } else {
        console.error("Server responded with an error:", response.statusText);
        MySwal.fire({
          title: "Error",
          text: "Something went wrong. Please try again.",
          icon: 'error',
        });
      }
    } catch (error) {
      console.error("Error submitting payment details:", error);
      // MySwal.fire({
      //   title: "Error",
      //   text: "Something went wrong. Please try again.",
      //   icon: 'error',
      // });
    }
    finally {
      setLoadingbutton(false);
    }
  };







  useEffect(() => {
    loaduser();
  }, []);
  const loaduser = async () => {
    // setActiveButton(dateRange);

    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    setLoading(true);

    let url = `${process.env.REACT_APP_API_URL}/withdraw_history.php`;
    //  console.warn(url);
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    // alert(user_id)
    // formData.append('market_id', gameid);
    formData.append("dev_id", dev_id);
    // formData.append("type", "2");


    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios
      .post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        //  alert(res);
        if (res != undefined) {
          const objectRes = JSON.parse(res);
          setUsers(objectRes);
          console.warn(objectRes);
        }
      })
      .finally(() => {
        setLoading(false);
      });
  };


  const [profiles, setProfiles] = useState([]);

  useEffect(() => {
    loaduser1();
  }, []); // Empty dependency array for initial component mount

  const loaduser1 = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    try {
      const response = await axios.post(url, formData);
      console.log("API Response:", response);
      const res = response.data;
      const withdraw = response.data.withdraw_open_time;
           setwithdraw(withdraw)
      if (res !== undefined) {
        setProfiles(res);
        console.warn(res);
      }
    } catch (error) {
      console.error("Error fetching profile data: ", error);
    }
  };

  function convertTo12Hour(timeString) {
    // Check if timeString is a string
    if (typeof timeString !== 'string') {
      console.error('Invalid time string:', timeString);
      return '';
    }
  
    // Split the time range into start and end times
    const [start, end] = timeString.split(" To ");
  
    // Function to convert a single time from 24-hour format to 12-hour format
    const convertTime = (time) => {
      const [hour, minute] = time.split(":");
      let hours = parseInt(hour);
      const period = hours >= 12 ? "PM" : "AM";
      hours = hours % 12 || 12; // Convert to 12-hour format and handle "00:00" as "12:00 AM"
      return `${hours}:${minute} ${period}`;
    };
  
    // Return the converted start and end times in 12-hour format
    return `${convertTime(start)} To ${convertTime(end)}`;
  }
  


  return (
    <div className="Add_bank-detail WithdrawAmt bg-image_all">
      <Container className="withdrawpayment">

        <div className="bg-white">
          <div className="d-flex align-items-center amoutwithdraw">
            <IoMdWallet className="walleticon" />
            <div className="amount d-flex align-items-center">
              <MdCurrencyRupee />
              {profiles.credit}
            </div>
          </div>
          <div className="inputbox">
            <input
              type="number"
              className="form-control"
              placeholder="Points"
              value={withdrawAmount}
              onChange={(e) => setWithdrawAmount(e.target.value)}
            />
          </div>

          <div className="inputbox">
            <p className="">Select Payment Option</p>
            <Form.Select
              aria-label="Default select example"
              value={paymentOption}
              onChange={(e) => setPaymentOption(e.target.value)}
            >
              <option>Select Payment Option</option>
              <option value="1">PhonePe</option>
              <option value="2">Paytm</option>
              <option value="3">GPay</option>
              <option value="4">Bank</option>
            </Form.Select>
          </div>

          <div className="inputbox">
            <Button type="submit" disdisabled={loadingbutton} className="w-100 Submit_btn" onClick={handleWithdraw}>
              Withdraw
              {loadingbutton && <Spinner animation="border" />}
            </Button>
          </div>
          <div className="info_box">
            <p className="text-danger">Withdraw available from   
              {/* {withdraw} */}
               {withdraw && convertTo12Hour(withdraw)}
              
              </p>
          </div>

        </div>
        <div className="bg-white">
          <h4 className="mb-0 text-danger text-center">Withdraw History</h4>
          {loading1 ? (
            <div className="spinner-wrapper">
              <div className="loadernew2"></div>
            </div>
          ) : (
            <div>
              {users && users.length > 0 ? (
                users.map((user, index) => (
                  <div className="d-flex align-items-center justify-content-between padding_5" key={index}>
                    <div className="logo_custum">
                      <img src={Logo} alt="logo" />
                    </div>
                    <div className="amountwithdrawal_custm">
                      <h3><MdCurrencyRupee /> {user.tr_value}
                        <span>{user.created_at}</span>
                      </h3>
                    </div>
                    <div className="statussuccess">
                      <p className="success"><span
                                  style={{
                                    color:
                                      user.tr_status === "Success"
                                        ? "green"
                                        : "red",
                                  }}
                                >
                                  {user.tr_status}
                                </span></p>
                    </div>
                  </div>
                ))
              ) : (
                <div className="mt-5">
                  <p>No data available or something went wrong.</p>
                </div>
              )}
            </div>
          )}

          {/* <div className="d-flex align-items-center justify-content-between padding_5">
            <div className="logo_custum">
              <img src={Logo} />
            </div>

            <div className="amountwithdrawal_custm">
              <h3><MdCurrencyRupee /> 2000
                <span>2023-12-09 17:02:49</span>
              </h3>

            </div>

            <div className="statussuccess">
              <p className="success">Success</p>
            </div>
          </div>
          <div className="d-flex align-items-center justify-content-between padding_5">
            <div className="logo_custum">
              <img src={Logo} />
            </div>

            <div className="amountwithdrawal_custm">
              <h3><MdCurrencyRupee /> 2000
                <span>2023-12-09 17:02:49</span>
              </h3>

            </div>

            <div className="statussuccess">
              <p className="success">Success</p>
            </div>
          </div> */}
        </div>
        {/* <Row justify={"space-between"} className="linkBox">
          <Col xs={24}>
            <h5 className="mt-2">Withdraw Method</h5>
          </Col>
          <Link to="/Withdrawhistory">
            <div className="">
              <p>Withdraw History</p>
            </div>
          </Link>

          <Link to="/Add-Bank-Detais">
            <div className="">
              <p>Add Bank Detail</p>
            </div>
          </Link>
        </Row> */}
      </Container>
      <ToastContainer />
    </div>
  );
}

export default WithdrawAmount;
