import React, { useState, useEffect } from "react";
import { Container, Button, Row, Col } from "react-bootstrap";
import { Link } from "react-router-dom";
import axios from "axios";
import { Carousel } from "antd";
import logo from "../../assets/img/logo.png";
import play from "../../assets/img/check-ch.png";
import whatsappimage from "../../assets/img/whatsappicon.png";
import wallet from "../../assets/img/wallet.png";
import callicon from "../../assets/img/callicon.png";
import staricon from "../../assets/img/staricon.png";
import playoff from "../../assets/img/icons8-cross-48.png";
import chart from "../../assets/img/icons8-signal-96.png";
// import call_bg from "../../assets/img/call_bg.png";
import { FaPlay } from "react-icons/fa";
import { VscDebugStart } from "react-icons/vsc";
import { FcStart } from "react-icons/fc";
import { RiBarChartGroupedLine } from "react-icons/ri";
import { FaArrowRight, FaTelegram } from "react-icons/fa";
import { IoIosCall } from "react-icons/io";
import { FaWhatsapp } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { IoMdPlay } from "react-icons/io";
import add_funds from '../../assets/img/sidebarimage/add_funds.png'
import withdrawal from '../../assets/img/sidebarimage/withdrawal.png'




import { BsGraphUpArrow } from "react-icons/bs";

import {
  StarFilled,
  FileTextOutlined,
  PlayCircleFilled,
} from "@ant-design/icons";


const phoneNumbertl = "9694700216"; // Replace with your WhatsApp business number
const messagetl = "Hello! I need assistance."; // Replace with your default message

const TlLink = `tg://msg?phone=${phoneNumbertl}&text=${encodeURIComponent(messagetl)}`;
export default function Play() {
  const [users, setUsers] = useState([]);
  const [sliders, setUserslider] = useState([]);
  const [Slider_url, setSlider_url] = useState([]);
  const [usersdata, setUsersdata] = useState([]);
  const [loading1, setLoading] = useState(false);
  const navigate = useNavigate();
  const [profiles, setProfiles] = useState([]);
  const [number, setnumber] = useState([]);
  const [telegram, settelegram] = useState([]);
  console.warn("yyyyyy",telegram)


  const phoneNumber = "{91+number}"; // Replace {91+number} with your WhatsApp business number
  const message = "Hello! I need assistance."; // Replace this message with your desired default message

  const whatsappLink = `https://api.whatsapp.com/send?phone=91+${number}&text=${encodeURIComponent(
    message
  )}`;

  const telegramLoginUrl = `{telegram}`;
  console.warn("ttttttttt",telegramLoginUrl)

  const handleReload = () => {
    window.scrollTo(0, 0);
  };

  useEffect(() => {
    loaduser();
    slider();
    getprofile();
  }, []);



  useEffect(() => {
    loaduser();
  }, []); // Empty dependency array for initial component mount

  const getprofile = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");

    let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);

    try {
      const response = await axios.post(url, formData);
      console.log("API Response:", response);
      const res = response.data;
      if (res !== undefined) {
        setProfiles(res);
        setnumber(res.genral_setting_whatsapp);
        settelegram(res.telegram);
        console.warn(res);
      }
    } catch (error) {
      console.error("Error fetching profile data: ", error);
    }
  };

  const loaduser = async () => {
    const user_id = localStorage.getItem("userid");
    const dev_id = localStorage.getItem("dev_id");
    // alert(dev_id)
    setLoading(true);

    // alert(user_id);
    let url = `${process.env.REACT_APP_API_URL}/Disawar_all_market.php`;
    // console.warn(url);
    const formData = new FormData();
    formData.append("app_id", process.env.REACT_APP_API_ID);
    formData.append("user_id", user_id);
    formData.append("dev_id", dev_id);
    // alert(dev_id)
    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios
      .post(url, formData, config)
      .then(function (response) {
        const res = JSON.stringify(response.data.data);
        const objectRes = JSON.parse(res);
        setUsers(objectRes);
        console.warn(objectRes);
      })
      .finally(() => {
        // setTimeout(() => {
        setLoading(false);
        // }, 1000);
      });
  };
  const slider = async () => {
    let url = `${process.env.REACT_APP_API_URL}/slider.php`;

    const formData = new FormData();
    // alert(dev_id)
    var config = {
      method: "POST",
      url: url,
      body: formData,
    };
    axios.post(url, formData, config).then(function (response) {
      const res = JSON.stringify(response.data.data);
      const baseurl = response.data.Slider_url;
      setSlider_url(baseurl);
      console.warn(baseurl);
      const objectRes = JSON.parse(res);
      setUserslider(objectRes);
      console.warn(objectRes);
    });
  };

  // const handleClick = (id) => {
  //   window.location.href = `/Pana?id=${id}`;
  // };
  const handleClick = (id, name) => {
    localStorage.setItem('market_id', id);
    localStorage.setItem('market_name', name);
    // localStorage.setItem('market_name',name);
    // window.location.href = `/Jodi-Game`;
    // navigate('/Pana');
    navigate('/Jodi-Game');

  };

  const handleClick1 = async (id) => {
    navigate('/Result-history');
    localStorage.setItem('marketchart_id', id,);
    // const market_id = localStorage.getItem("market_idd");

    // localStorage.setItem('market_idd', id);
    // try {
    //   const response = await fetch(`${process.env.REACT_APP_API_URL}/webviewresult.php?id=${id}`);
    //   const result = await response.text(); // Assuming the API returns HTML content
    //   const newWindow = window.open('', '_blank');
    //   newWindow.document.write(result);
    // } catch (error) {
    //   console.error('Error fetching data:', error);
    // }
  };
  const handleClickclose = () => {
    if ("vibrate" in navigator) {
      // vibration API supported
      navigator.vibrate(1000);
    }
  };
  return (
    <>
      {profiles && profiles.is_playstore == 0 ?

        <section id="play" className="margin-bottom-88 mb-0">
          <div className="playsection">
            <div className="playsection_fixed">
              <div className="fixed_carousel">
              </div>
            </div>
            <div className="height_65">

              <div className=" border-radius-8">
                <div className="playg">
                  {users &&
                    users.map((user) => (
                      <>
                        <div className="new">
                          {user.is_play == 1 ? (
                            <div className="d-flex align-items-center justify-content-between padding-5">
                              <div className="div text-white text-center chartimage_close">
                                <div>

                                  <span onClick={() => {
                                    handleClick1(user.id);
                                  }} className="d-block">
                                    <BsGraphUpArrow className="croseimg" />
                                  </span>
                                  <span className="text-bold">Chart</span>

                                </div>
                              </div>
                              <div className="div">
                                <div className="marketname text-center">
                                  <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center text-dark">
                                    {user.name}
                                  </h4>
                                  <small className="text-danger p-0">
                                    <div className="inputbox">
                                      <p style={{ color: "red" }}>
                                        {user.result == null ? (
                                          <div className="inputbox">
                                            <small className="text-danger p-0">
                                              [**]
                                            </small>
                                          </div>
                                        ) : (

                                          <div className="inputbox">
                                            <p style={{ color: "green" }}>
                                              {user.result}
                                                </p>



                                          </div>

                                        )}                                      </p>
                                    </div>
                                  </small>

                                  <p
                                    onClick={() => {
                                      handleReload();
                                    }}
                                    className="playgames p-0 "
                                  >
                                    Market opened{" "}
                                  </p>

                                  <div className="marketnamepaly">
                                    <div className="date_time">
                                      <p className="animate-charcter">
                                        <span className="openclose"></span>{" "}
                                        {user.open_time}
                                      </p>
                                      ||
                                      <p className="animate-charcter">
                                        {user.time}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div onClick={() => { handleClick(user.id); }} className="div text-dark w-20">
                  <span><img className="playimg" src={play} alt="" /></span>
                  <p className="openn">Open</p>
                </div> */}
                              <div

                                className="div text-dark text-center"
                              >
                                <div className="playbtn_open">
                                  <IoMdPlay />
                                </div>
                                <span className="text-bold">Open</span>

                              </div>
                            </div>

                          ) : (
                            <div className="d-flex    align-items-center justify-content-between padding-5">
                              <div className="div text-white text-center chartimage_open">
                                <div > 
                                  <span onClick={handleClickclose} className="d-block"  >
                                    <BsGraphUpArrow className="croseimg" />
                                  </span>
                                  <span>Chart</span>
                                </div>
                              </div>
                              <div className="div">
                                <div className="marketname text-center">
                                  <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center text-dark">
                                    {user.name}
                                  </h4>
                                  <small className="text-danger p-0">
                                    <div className="inputbox">
                                      <p style={{ color: "red" }}>
                                      {user.result == null ? (
                                  <div className="inputbox">
                                    <small className="text-danger p-0">
                                      [**]
                                    </small>
                                  </div>
                                ) : (

                                  <div className="inputbox">
                                    <p style={{ color: "green" }}>
                                      {user.result}
                                        </p>



                                  </div>

                                )}                                      </p>
                                    </div>
                                  </small>

                                  <p
                                    onClick={() => {
                                      handleReload();
                                    }}
                                    className="playgames1 p-0 text-danger "
                                  >
                                    Market Closed{" "}
                                  </p>
                                  <div className="marketnamepaly ">
                                    <div className="date_time ">
                                      <p className="animate-charcter text-dark">
                                        {user.open_time}
                                      </p>
                                      ||
                                      <p className="animate-charcter">
                                        {user.time}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div onClick={handleClickclose} className="div text-dark text-center">
                                <div className="playbtn_close">
                                  <IoMdPlay />
                                </div>
                                <span className="text-bold">Close</span>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {loading1 && (
            // <>
            // <div className="loader_roomcode">
            //   <img src={loader} alt="loader" />
            // </div>
            // </>
            <div className="spinner-wrapper">
              <div className="loadernew2"></div>
            </div>
          )}
        </section>
        :
        ""
      }
      {profiles && profiles.is_playstore == 1 ?

        <section id="play" className="margin-bottom-88 mb-0">
          <div className="playsection">
            <div className="playsection_fixed">
              <div className="fixed_carousel">
                <div className="heightcarosuksel">

                  <Carousel autoplay>
                    {sliders &&
                      sliders.map((user) => (
                        <div>
                          <img
                            // src={Slider_url/sliders}
                            src={`${Slider_url}/${user.images}`}
                            alt="banner"
                            className="img-fluid"
                          />
                        </div>
                      ))}
                  </Carousel>
                </div>
                <div className="d-flex justify-content-between socialicon_play">
                  <Link to={whatsappLink} className="button_custum d-flex align-items-center justify-content-center">
                    <div className="imagewhatsapp">
                      <img src={whatsappimage} />
                    </div>
                    <span>Whatsapp</span>
                  </Link>
                  <Link to={telegram} className="button_custum d-flex align-items-center justify-content-center">
                    <div className="imagewhatsapp">
                      <FaTelegram />
                    </div>
                    <span>Telegram</span>
                  </Link>
                  {/* <Link to="tel:+91-9694700216" className="button_custum d-flex align-items-center justify-content-center"> */}
                  {/* <Link to={`tel:+91${number}`} className="button_custum d-flex align-items-center justify-content-center">
                    <div className="imagewhatsapp">
                      <img src={callicon} />
                    </div>
                    <span> Call Us</span>
                  </Link> */}
                </div>
                <div className="d-flex justify-content-between socialicon_play">
                  {/* <Link to="/Starline-Market" className="button_custum d-flex align-items-center justify-content-center">
                    <div className="imagewhatsapp">
                      <img src={staricon} />
                    </div>
                    <span>STARLINE</span>
                  </Link> */}
                  <Link to="/AddPayment" className="button_custum d-flex align-items-center justify-content-center">
                    <div className="imagewhatsapp">
                      <img src={add_funds} />
                    </div>
                    <span>Add Fund</span>
                  </Link>
                  {/* <Link to="/Disawar-Market" className="button_custum d-flex align-items-center justify-content-center">
                    <div className="imagewhatsapp">
                      <img src={staricon} />
                    </div>
                    <span>GALI DISAWER</span>
                  </Link> */}
                  <Link to="/WithdrawAmount" className="button_custum d-flex align-items-center justify-content-center">
                    <div className="imagewhatsapp">
                      <img src={withdrawal} />
                    </div>
                    <span>Withdrawal </span>
                  </Link>

                </div>

              </div>
            </div>
            <div className="height_65">

              <div className=" border-radius-8">
                <div className="playg">
                  {users &&
                    users.map((user) => (
                      <>
                        <div className="new">
                          {user.is_play == 1 ? (
                            <div className="d-flex align-items-center justify-content-between padding-5">
                              <div className="div text-white text-center chartimage_close">
                                <div onClick={() => {
                                  handleClick1(user.id);
                                }}>

                                  <span className="d-block">
                                    <BsGraphUpArrow className="croseimg" />
                                  </span>
                                  <span className="text-bold">Chart</span>

                                </div>
                              </div>
                              <div className="div">
                                <div className="marketname text-center">
                                  <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center text-dark">
                                    {user.name}
                                  </h4>
                                  <small className="text-danger p-0">
                                    <div className="inputbox">
                                      <p style={{ color: "red" }}>
                                      {user.result == null ? (
                                  <div className="inputbox">
                                    <small className="text-danger p-0">
                                      [**]
                                    </small>
                                  </div>
                                ) : (

                                  <div className="inputbox">
                                    <p style={{ color: "green" }}>
                                      {user.result}
                                         </p>



                                  </div>

                                )}                                      </p>
                                    </div>
                                  </small>

                                  <p
                                    onClick={() => {
                                      handleReload();
                                    }}
                                    className="playgames p-0 "
                                  >
                                    Market opened{" "}
                                  </p>

                                  <div className="marketnamepaly">
                                    <div className="date_time">
                                      <p className="animate-charcter">
                                        <span className="openclose"></span>{" "}
                                        {user.open_time}
                                      </p>
                                      ||
                                      <p className="animate-charcter">
                                        {user.time}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              {/* <div onClick={() => { handleClick(user.id); }} className="div text-dark w-20">
                  <span><img className="playimg" src={play} alt="" /></span>
                  <p className="openn">Open</p>
                </div> */}
                              <div
                                onClick={() => {
                                  handleClick(user.id, user.name);
                                }}
                                className="div text-dark text-center"
                              >
                                <div className="playbtn_open">
                                  <IoMdPlay />
                                </div>
                              </div>
                            </div>

                          ) : (
                            <div className="d-flex    align-items-center justify-content-between padding-5">
                              <div className="div text-white text-center chartimage_close">
                                <div   onClick={() => {
                                    handleClick1(user.id);
                                  }}>
                                  <span onClick={handleClickclose} className="d-block">
                                    <BsGraphUpArrow className="croseimg" />
                                  </span>
                                  <span>Chart </span>
                                </div>
                              </div>
                              <div className="div">
                                <div className="marketname text-center">
                                  <h4 className="animate-charcter flex-wrap marketnamenew mb-0 d-flex justify-content-center market_name text-center text-dark">
                                    {user.name}
                                  </h4>
                                  <small className="text-danger p-0">
                                    <div className="inputbox">
                                      <p style={{ color: "red" }}>
                                      {user.result == null ? (
                                  <div className="inputbox">
                                    <small className="text-danger p-0">
                                      [**]
                                    </small>
                                  </div>
                                ) : (

                                  <div className="inputbox">
                                    <p style={{ color: "green" }}>
                                      {user.result}
                                        </p>



                                  </div>

                                )}                                      </p>
                                    </div>
                                  </small>

                                  <p
                                    onClick={() => {
                                      handleReload();
                                    }}
                                    className="playgames1 p-0 text-danger "
                                  >
                                    Market Closed{" "}
                                  </p>
                                  <div className="marketnamepaly ">
                                    <div className="date_time ">
                                      <p className="animate-charcter text-dark">
                                        {user.open_time}
                                      </p>
                                      ||
                                      <p className="animate-charcter">
                                        {user.time}
                                      </p>
                                    </div>
                                  </div>
                                </div>
                              </div>
                              <div onClick={handleClickclose} className="div text-dark text-center">
                                <div className="playbtn_close">
                                  <IoMdPlay />
                                </div>
                              </div>
                            </div>
                          )}
                        </div>
                      </>
                    ))}
                </div>
              </div>
            </div>
          </div>
          {loading1 && (
            // <>
            // <div className="loader_roomcode">
            //   <img src={loader} alt="loader" />
            // </div>
            // </>
            <div className="spinner-wrapper">
              <div className="loadernew2"></div>
            </div>
          )}
        </section>
        :
        ""
      }
    </>
  );
}
