import React, { useState, useEffect } from 'react';
import logo from "../../assets/img/logo.png";
import axios from 'axios';
import { FaYoutube } from "react-icons/fa";
// import videoimge from "../../assets/img/videoimge.jpg";
import depositt from "../../assets/img/video/deposit.png";
import withdraw from "../../assets/img/video/withdrawl.png";
import game from "../../assets/img/video/gameplay.png";
import { Link } from 'react-router-dom';


export default function Howtoplay() {
    const [data, setUserData] = useState({});
    const [youtubeUrl, setYoutubeUrl] = useState('');
    const [play, setplay] = useState('');
    const [deposit, setdeposit] = useState('');

    // Function to load user data from the API
    const loadUser = async () => {
        const user_id = localStorage.getItem("userid");
        const dev_id = localStorage.getItem("dev_id");

        let url = `${process.env.REACT_APP_API_URL}/get_user_profile.php`;
        const formData = new FormData();
        formData.append("app_id", process.env.REACT_APP_API_ID);
        formData.append("user_id", user_id);
        formData.append("dev_id", dev_id);

        var config = {
            method: "POST",
            url: url,
            data: formData,
        };

        axios(config)
            .then(function (response) {
                const res = response.data;
                if (res.success === "1") {
                    setUserData(res);
                    setYoutubeUrl(res.withdraw_video_link); // Set the YouTube URL from the API response
                    setplay(res.play_game_link); // Set the YouTube URL from the API response
                    setdeposit(res.deposit_video_link); // Set the YouTube URL from the API response
                }
            })
            .catch(function (error) {
                console.error("Error fetching profile data: ", error);
            });
    };

    useEffect(() => {
        loadUser(); // Call the function when the component mounts
    }, []);

    // Function to handle button click and open YouTube video
    const handleWatchYoutube = () => {
        if (youtubeUrl) {
            window.open(youtubeUrl, "_blank"); // Open YouTube URL in a new tab
        } else {
            alert('YouTube URL not available');
        }
    };
    const handleWatplay = () => {
        if (play) {
            window.open(play, "_blank"); // Open YouTube URL in a new tab
        } else {
            alert('YouTube URL not available');
        }
    };
    const handleWatplaydeposit = () => {
        if (deposit) {
            window.open(deposit, "_blank"); // Open YouTube URL in a new tab
        } else {
            alert('YouTube URL not available');
        }
    };

    return (
        <div className="wininghistory" id="howtoplay">
            <div className="user_profile">
                <div className="logoImg d-flex justify-content-center">
                    <img src={logo} alt="Logo" className="img-fluid" />
                </div>
                <div className="pagenotice"  >


                    <div onClick={handleWatchYoutube}> <img src={withdraw} alt="Logo" style={{ borderRadius: "15px", width: "100%" }} />  </div>
                    <div onClick={handleWatplay}>                 <img src={game} alt="Logo" style={{ borderRadius: "15px", width: "100%", margin: "20px 0px" }} />
                    </div>
                    <div onClick={handleWatplaydeposit}>        <img src={depositt} alt="Logo" style={{ borderRadius: "15px", width: "100%" }} />    </div>



                </div>





            </div>
        </div>
    );
}
